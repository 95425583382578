import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../apiConfig";
import DeleteButton from "../components/Buttons/DeleteButton";
import HistoryButton from "../components/Buttons/HistoryButton";
import PlusButton from "../components/Buttons/PlusButton";
import SaveButton from "../components/Buttons/SaveButton";
import DeleteDialog from "../components/Dialogs/DeleteDialog";
import DropdownMenu from "../components/Dropdowns/DropdownMenu";
import MenuForm from "../components/Forms/MenuForm";
import Loader from "../components/Loaders/Loader";
import { SortableWrapper } from "../components/Sortables/SortableItem";
import SortableList from "../components/Sortables/SortableList";
import {
  loadLinks,
  removeLink,
  setCurrentFilter,
  setLinks,
} from "../features/menuSlice";

export default function Menu() {
  const dispatch = useDispatch();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [addContainer, setAddContainer] = useState();
  const lang = useSelector((state) => state.lang.queriesLang);
  const currentFilter = useSelector((state) => state.menu.currentFilter);

  const [dropdownData, setdropdownData] = useState();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState();
  const [isLinkOpen, setLinksOpen] = useState(false);

  // This function is only for sorting the menu while rendering and not inside the state itself
  // For any changes to state, make sure to use the id property instead of index
  const sortMenu = (links) => {
    const linksTemp = JSON.parse(JSON.stringify(links));
    const sortedArray = linksTemp.sort((a, b) => {
      return a.key.localeCompare(b.key);
    });
    return sortedArray;
  };

  const links = useSelector(({ menu }) => {
    return { parameters: sortMenu(menu.links.parameters) };
  });

  const changes = useSelector((state) => state.change.changes);

  // Getting the initial length to know which id to put to new elements
  const [initialLength, setInitialLength] = useState(0);

  const [activeItemId, setActiveItemId] = useState();

  useEffect(
    () => (document.title = `${params.bot?.toUpperCase()}: Menu Parameters`),
    [],
  );

  useEffect(() => {
    setIsLoading(true);
    // Fetching the filters for the dropdown
    api
      .get(`${params.bot}/filters`, {
        params: { lang: lang },
      })
      .then((res) => {
        // Arranging the dropdown data to match the eufemia component data expectations
        let arrangedData = [];
        if (res && res.data && res.data.body && res.data.body.parameters) {
          arrangedData = res.data.body.parameters.reduce((acc, parameter) => {
            if (parameter.child_parameters) {
              parameter.child_parameters.forEach((childParam) => {
                acc.push({ content: [parameter.key, childParam.key] });
              });
            }
            return acc;
          }, []);
        }
        setdropdownData(arrangedData);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Unable to load filters");
        console.error(err);
        setIsLoading(false);
      });

    return () => {
      dispatch(setCurrentFilter(null));
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    // Fetching the links associated to a filter
    if (dropdownData && currentFilter !== null) {
      let filter = currentFilter ? currentFilter : dropdownData[0];

      api
        .get(`${params.bot}/menu`, {
          params: {
            lang: lang,
            filter: transformItem(filter),
          },
        })
        .then((res) => {
          const linksWithIds =
            res.data.body && res.data.body.parameters
              ? res.data.body.parameters.map((param, index) => ({
                  id: index + 1,
                  ...param,
                }))
              : [];

          dispatch(loadLinks(linksWithIds));
          setInitialLength(linksWithIds.length);

          setIsLoading(false);
        })
        .catch(() => {
          // toast.error("Unable to load quick menu links for this filter");
          setIsLoading(false);
        });
    }

    return () => {
      dispatch(loadLinks([]));
    };
  }, [currentFilter, lang]);

  function setLinkStatus(data) {
    setLinksOpen(data);
  }

  function handleRemove() {
    dispatch(removeLink(itemToDelete));
    if (itemToDelete === activeItemId) {
      setAddContainer();
    }
  }

  // Add the component to the view, when the user clicks Save, it set the container to nothing: onClick={() => { setAddContainer() }}
  function handleAdd() {
    setActiveItemId();
    setAddContainer(
      <MenuForm
        id={initialLength + 1}
        initialData={{ name: "", url: "" }}
        onClick={() => {
          setAddContainer();
          setActiveItemId();
        }}
      />,
    );
    setInitialLength(initialLength + 1);
  }

  function handleEdit(item) {
    setActiveItemId(item.id);
    setAddContainer(
      <MenuForm
        id={item.id}
        initialData={{ name: item.key, url: item.value }}
        onClick={() => {
          setAddContainer();
          setActiveItemId();
        }}
      />,
    );
  }

  const handleDelete = (item) => {
    setItemToDelete(item.id);
    setDeleteDialogOpen(true);
  };

  const renderListItem = (item) => (
    <SortableList.Item id={item.id}>
      <SortableWrapper
        selected={activeItemId === item.id}
        onClick={() => {
          handleEdit(item);
        }}
      >
        <SortableButton disabled={activeItemId === item.id}>
          {item.key}
        </SortableButton>
        <DeleteButton
          handleDelete={() => {
            handleDelete(item);
          }}
          border={true}
        />
      </SortableWrapper>
    </SortableList.Item>
  );

  return !isLoading ? (
    <MenuWrapper>
      {dropdownData && (
        <DropdownMenu
          data={dropdownData}
          selectedItem={currentFilter}
          onChange={(item) => {
            dispatch(setCurrentFilter(item));
            setAddContainer();
            setActiveItemId();
          }}
          setLinkStatus={setLinkStatus}
        />
      )}
      {!isLoading && !isLinkOpen && links.parameters ? (
        <SortableList
          items={links.parameters}
          onChange={(updatedItems) => dispatch(setLinks(updatedItems))}
          renderItem={renderListItem}
        />
      ) : (
        <Loader />
      )}
      {currentFilter && (
        <>
          {addContainer}
          {addContainer ? (
            <br />
          ) : (
            <PlusButton handleAdd={handleAdd} active={false} />
          )}
          <ButtonWrapper>
            <SaveButton disabled={!changes} />
            <HistoryButton />
          </ButtonWrapper>
        </>
      )}
      {isDeleteDialogOpen ? (
        <DeleteDialog
          handleRemove={handleRemove}
          type={""}
          closeDialog={() => setDeleteDialogOpen(false)}
        />
      ) : null}
    </MenuWrapper>
  ) : (
    <Loader />
  );
}

export function transformItem(item) {
  return item && item.content
    ? item.content
        .join(".")
        .split(" ")
        .join("_")
        .replace("å", "a")
        .replace("ø", "o")
        .replace("æ", "ae")
        .replace("/", "")
        .replace(",", "")
    : "";
}
const SortableButton = styled.button`
  text-align: left;
`;
const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: -3rem;
  // padding-top: var(--spacing-x-large);

  @media screen and (max-height: 1000px) {
    // margin-top: var(--spacing-x-large);
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
