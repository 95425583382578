function getContent(history) {
  try {
    const menuMapKeys = Object.keys(history).filter((key) =>
      key.startsWith("chat.menu.map"),
    );

    // Modify the creation of menuMapObjects to append the key value to the parameters
    const menuMapObjects = menuMapKeys
      .map((key) => {
        const parameters = history[key]["parameters"];
        // Append key value to each parameter's key
        return parameters?.map((param) => ({
          ...param,
          key: `${key.split("chat.menu.map.")[1]}.${param.key}`,
        }));
      })
      .flat()
      .filter((item) => item != undefined);

    return {
      en: [
        history[`chat.welcome.messages.en`]["parameters"],
        history[`chat.filter.key.en`]["parameters"],
      ]
        .flat()
        .filter((item) => item !== undefined),

      no: [
        history[`chat.welcome.messages.no`]["parameters"],
        history[`chat.filter.key.no`]["parameters"],
      ]
        .flat()
        .filter((item) => item !== undefined),
      other: [
        ...menuMapObjects.flat(),
        {
          key: "chat.bot.state.switch",
          value: history["chat.bot.state.switch"],
        },
        {
          key: "chat.agent.state.switch",
          value: history["chat.agent.state.switch"],
        },
      ]
        .flat()
        .filter((item) => item !== undefined),
    };
  } catch (e) {
    return;
  }
}

/**
 * Compares two histories and returns an array of changes.
 *
 * @param {Object} oldHistory - The previous state of the content.
 * @param {Object} newHistory - The current state of the content.
 * @param {Object} t - The translation object
 * @returns {Array} An array of change strings (Added, Edited, Deleted).
 */
function compareContent(oldHistory, newHistory) {
  if (
    Object.keys(oldHistory).length === 0 ||
    Object.keys(newHistory).length === 0
  ) {
    return;
  }

  // { change: string, content: {key: string, value: string}}[]
  // type change = { type: "edited" | "added" | "deleted", key: string, oldValue: string | undefined, newValue: string | undefined}
  const changes = [];

  const oldContent = getContent(oldHistory);
  const newContent = getContent(newHistory);

  // Helper function to recursively compare parameters and their child parameters
  function compareParameters(oldParams, newParams) {
    oldParams.forEach((oldParam) => {
      const newParam = newParams.find((p) => p.key === oldParam.key);

      if (!newParam) {
        changes.push({
          type: "deleted",
          key: oldParam.key,
          oldValue: oldParam.value,
          newValue: undefined,
        });
      } else if (
        JSON.stringify(oldParam.value) !== JSON.stringify(newParam.value)
      ) {
        changes.push({
          type: "edited",
          key: oldParam.key,
          oldValue: oldParam.value,
          newValue: newParam.value,
        });
      }

      if (
        oldParam &&
        oldParam.child_parameters?.length &&
        newParam &&
        newParam.child_parameters?.length
      ) {
        // Recursively compare child parameters
        compareParameters(oldParam.child_parameters, newParam.child_parameters);
      }
    });

    newParams.forEach((newParam) => {
      const oldParam = oldParams.find((p) => p.key === newParam.key);
      if (!oldParam) {
        changes.push({
          type: "added",
          key: newParam.key,
          oldValue: undefined,
          newValue: newParam.value,
        });
      }
    });
  }

  // Compare English content (en)
  if (oldContent.en && newContent.en) {
    compareParameters(oldContent.en || [], newContent.en || []);
  }

  // Compare Norwegian content (no)
  if (oldContent.no && newContent.no) {
    compareParameters(oldContent.no || [], newContent.no || []);
  }

  // Compare other
  if (oldContent.other && newContent.other) {
    compareParameters(oldContent.other || [], newContent.other || []);
  }

  return changes;
}

function compareHistory(history, index) {
  // Ensure index and index + 1 are within bounds
  if (index < 0 || index >= history.length - 1) {
    return;
  }

  const currentHistory = history[index];
  const nextHistory = history[index + 1];

  // Check if both current and next history entries exist and have valid content
  if (
    currentHistory &&
    nextHistory &&
    currentHistory.content &&
    nextHistory.content
  ) {
    try {
      return compareContent(
        JSON.parse(nextHistory.content),
        JSON.parse(currentHistory.content),
      );
    } catch (e) {
      console.log("Error parsing JSON:", e);
      return;
    }
  }
}

export function getChanges(history) {
  // Store all the comparisons to avoid calling compareHistory multiple times
  return history?.map((item, index) => {
    const changes = compareHistory(history, index);

    return {
      date: item.date,
      version: item.version,
      description: item.description,
      changes,
    };
  });
}
