import styled from "@emotion/styled";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import api from "../../apiConfig";
import Button from "./Button";
import CloseButton from "../Buttons/CloseButton";
import HistoryDrawerContent from "../HistoryDrawerContent/HistoryDrawerContent";
import { Pagination } from "@dnb/eufemia";
import Loader from "../Loaders/Loader";
import { toast } from "react-hot-toast";
import { getChanges } from "../HistoryDrawerContent/utils";

export default function HistoryButton() {
  const [isopen, setIsOpen] = useState(false);

  const [historyData, setHistoryData] = useState({}); // Store the history data for each page
  const [content, setContent] = useState(<></>);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const params = useParams();

  // Function to fetch the history based on the last version
  async function fetchHistory(lastVersionFetched) {
    setIsLoading(true);
    try {
      const config =
        lastVersionFetched !== undefined
          ? { params: { last_version_fetched: lastVersionFetched } }
          : {}; // If lastVersionFetched is not defined, pass an empty object

      const response = await api.get(`${params.bot}/history`, config);

      setIsLoading(false);
      return response.data;
    } catch (e) {
      setIsLoading(false);
      toast.error("Unable to load history");
    }
  }

  // Calculate the last version for a given page
  function calculateLastVersionForPage(pageNumber) {
    try {
      // Try to look at the last version of the precedent page
      const l = historyData[pageNumber - 1].length - 1;
      return historyData[pageNumber - 1][l]?.version;
    } catch (e) {
      // It fails if we haven't fetched the precedent page yet, so we try to guess the version
      const startVersion = historyData[1][0].version;
      const version = startVersion - 15 * (pageNumber - 1) + 1;

      return version > 1 ? version : 1;
    }
  }

  async function handlePageChange(pageNumber) {
    // Check if we have the data for this page already
    if (historyData[pageNumber]) {
      // If data is already cached, use it directly
      setContent(<HistoryDrawerContent history={historyData[pageNumber]} />);
      return;
    }

    const lastVersionFetched =
      pageNumber === 1 ? undefined : calculateLastVersionForPage(pageNumber); // The last version of the previous page
    const newHistory = await fetchHistory(lastVersionFetched);
    const newHistoryWithChanges = getChanges(newHistory);

    // Store the new data for future reference
    setHistoryData((prev) => ({
      ...prev,
      [pageNumber]: newHistoryWithChanges.slice(0, -1),
    }));

    // Set the content for the page
    setContent(
      <HistoryDrawerContent history={newHistoryWithChanges.slice(0, -1)} />,
    );
  }

  async function handleOnClick() {
    setIsOpen(true);

    await handlePageChange(1);
  }

  async function handleOnClose() {
    setIsOpen(false);
    setContent(<></>);
    setHistoryData({});
  }

  return (
    <>
      <Button onClick={handleOnClick}>{t("layout.History")}</Button>
      <Overlay isopen={isopen} onClick={handleOnClose} />
      <DrawerWrapper isopen={isopen}>
        <Header>
          <CloseButton handleClose={handleOnClose} />
          <TitleWrapper>{t("layout.History")}</TitleWrapper>
        </Header>
        {isopen && (
          <StyledPagination
            disabled={isLoading}
            {...(isLoading ? { isloading: "true" } : {})} // Conditionally add the isloading attribute
            align="center"
            page_count={10}
            on_change={async ({ pageNumber }) => {
              setIsLoading(true);
              setContent(<></>); // Eufemia doesn't clear correctly the content, so doing it manually
              await handlePageChange(pageNumber);
              setIsLoading(false);
            }}
          >
            {!isLoading ? (
              content
            ) : (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            )}
          </StyledPagination>
        )}
      </DrawerWrapper>
    </>
  );
}

const StyledPagination = styled(Pagination)`
  min-height: ${(props) => (props.isloading ? "90%" : "auto")};
  height: ${(props) => (props.isloading ? "90%" : "auto")};

  .dnb-pagination__content {
    height: 100%;
    min-height: 100%;
  }

  --color-emerald-green: #1e1e1e;
  --color-mint-green: #ffffff;
  --color-sea-green: #1e1e1e;
  --color-sea-green-30: #1e1e1e;

  .dnb-pagination__dots {
    color: #ffffff;
  }

  .dnb-button--tertiary {
    color: #cdcdcd;
  }
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DrawerWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 33%;
  height: 100%;
  background-color: #313131;
  color: #ffffff;
  overflow: auto;
  transform: translateX(${(props) => (props.isopen ? "0" : "100%")});
  opacity: ${(props) => (props.isopen ? "1" : "0")};
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  z-index: 1000;

  padding: var(--spacing-large);
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${(props) => (props.isopen ? "1" : "0")};
  transition: opacity 0.3s ease-in-out;
  pointer-events: ${(props) => (props.isopen ? "auto" : "none")};
  z-index: 999;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 4rem;
`;

const TitleWrapper = styled.div`
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-x-large);
  align-self: self-start;
`;
