import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../apiConfig";
import HistoryButton from "../components/Buttons/HistoryButton";
import PreviewButton from "../components/Buttons/PreviewButton";
import SaveButton from "../components/Buttons/SaveButton";
import WelcomeForm from "../components/Forms/WelcomeForm";
import Loader from "../components/Loaders/Loader";
import QueriesLangSwitch from "../components/Switchs/QueriesLangSwitch";
import { toggleQueriesLang } from "../features/langSlice";
import { resetInfos, setImage, setInfos } from "../features/welcomeSlice";

export default function WelcomePage() {
  const dispatch = useDispatch();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const [botLangs, setBotLangs] = useState([]);

  const { main, sub, image, welcomeDisclaimer, disclaimerUrl } = useSelector(
    (state) => state.welcome,
  );

  const lang = useSelector((state) => state.lang.queriesLang);
  const changes = useSelector((state) => state.change.changes);

  useEffect(
    () => (document.title = `${params.bot?.toUpperCase()}: Welcome Page`),
    [],
  );

  useEffect(() => {
    setIsLoading(true);
    // Fetching the welcome page infos
    api
      .get(`${params.bot}/welcome`, {
        params: {
          lang: lang,
        },
      })
      .then((res) => {
        dispatch(setInfos(res.data.body));
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Unable to load welcome page information");
        console.error(err);
        setIsLoading(false);
      });

    return () => {
      dispatch(resetInfos());
    };
  }, [params.bot, dispatch, lang]);

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`${params.bot}/botlang`)
      .then((res) => {
        setBotLangs(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);

        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (botLangs.length === 1 && botLangs[0] != lang) {
      dispatch(toggleQueriesLang());
    }
  }, [botLangs]);

  function handleFileChange(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = function () {
      dispatch(setImage(reader.result));
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  return !isLoading ? (
    <WelcomePageWrapper>
      {botLangs && botLangs.length === 2 ? <QueriesLangSwitch /> : null}
      <ContentWrapper>
        <ColumsWrapper>
          <ColumnDiv>
            <IconDiv>
              <Title>{t("welcomePage.Icon")}</Title>
              <IconWrapper>
                <Img src={image} alt={params.bot} />
              </IconWrapper>
              <FileUploadButton htmlFor="file-upload">
                {t("welcomePage.BrowseForPhoto")}
              </FileUploadButton>
              <input
                id="file-upload"
                type="file"
                accept=".svg"
                onChange={handleFileChange}
              />
            </IconDiv>
          </ColumnDiv>
          <Divider />
          <ColumnDiv>
            <TextDiv>
              <Title>{t("welcomePage.Text")}</Title>
              <WelcomeForm
                main={main}
                sub={sub}
                welcomeDisclaimer={welcomeDisclaimer}
                disclaimerUrl={disclaimerUrl}
              />
            </TextDiv>
          </ColumnDiv>
        </ColumsWrapper>
        <SaveButtonWrapper>
          <SaveButton style={{ width: "auto" }} disabled={!changes} />
        </SaveButtonWrapper>
      </ContentWrapper>
      <ButtonWrapper>
        <PreviewButton main={main} sub={sub} icon={image} />
        <HistoryButton />
      </ButtonWrapper>
    </WelcomePageWrapper>
  ) : (
    <Loader />
  );
}

const Divider = styled.div`
  height: 30rem;
  width: 1.5px;
  background-color: #313131;

  margin-left: 2rem;
  margin-right: 2rem;
`;

const WelcomePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  @media screen and (max-height: 1000px) {
    margin-top: var(--spacing-x-large);
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SaveButtonWrapper = styled.div`
  margin-top: 1rem;
`;

const ColumsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  margin-bottom: 0.625rem;
`;

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  width: 100%;

  input[type="file"] {
    display: none;
  }
`;

const IconDiv = styled.div`
  align-self: flex-end;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 1rem;
`;

const FileUploadButton = styled.label`
  background-color: #ffffff;
  color: #333333;

  border: 1px solid ${(props) => props.theme.buttonBorderColor};
  border-radius: 3.125rem;
  padding: 6px 27px 6px 27px;

  &:hover {
    background-color: #b3e4d8;
    border: 1px solid #b3e4d8;
    cursor: pointer;
  }

  &:active {
    background-color: #ffffff;
    color: #00343e;
    border: 1px solid #00343e;
  }

  &:disabled {
    background-color: #cdcdcd;
    border: none;
    opacity: 50%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;

const IconWrapper = styled.div`
  width: 10.625rem;
  height: 10.625rem;
  background-color: #313131;
  border: 1px solid white;
  border-radius: 0.5rem;

  margin-top: 1.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  width: 100%;
  max-width: 8rem;
`;

const Title = styled.div`
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-large);

  align-self: center;
`;
