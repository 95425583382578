import Accordion from "../Accordions/Accordion";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

export default function HistoryDrawerContent({ history }) {
  const { t, i18n } = useTranslation();

  function formatDate(date) {
    const locales = i18n.language == "no" ? "nb-NO" : "en-GB";
    const d = new Date(date);
    const formattedDate = d.toLocaleString(locales, {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
    });
    return formattedDate;
  }

  return (
    <ContentWrapper>
      {history &&
        history.map((item) => (
          <Item key={item.version}>
            <StyledDate>
              {formatDate(item.date)}, v{item.version}
            </StyledDate>
            <Accordion
              title={item.description}
              subtitleList={
                item.changes | (item.changes?.length > 0)
                  ? item.changes.map(
                    (change) =>
                      `${t(`history.${change.type}`)} ${change.key}`,
                  )
                  : [t("history.noHistory")]
              }
            >
              {item.changes?.map((change) => (
                <div key={change.key}>
                  <Key>{`${t(`history.${change.type}`)} ${change.key}`}</Key>
                  {change.type === "added" && (
                    <Value>
                      {t("history.value")}: {change.newValue}
                    </Value>
                  )}
                  {change.type === "deleted" && (
                    <Value>
                      {t("history.value")}: {change.oldValue}
                    </Value>
                  )}
                  {change.type === "edited" && (
                    <>
                      <Value>
                        {t("history.new")}: {change.newValue}
                      </Value>
                      <Value>
                        {t("history.old")}: {change.oldValue}
                      </Value>
                    </>
                  )}
                </div>
              ))}
            </Accordion>
          </Item>
        ))}
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const Key = styled.div`
  font-weight: bold;
  font-size: var(--font-size-medium);
`;

const Value = styled.div`
  font-size: var(--font-size-basis);
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-basis);
  padding-top: 1rem;
`;

const StyledDate = styled.div`
  font-size: var(--font-size-x-small);
`;
